<template>
  <div v-if="loaded">
    <section class="section pb-1 box unset-min-height">
      <div class=" columns">
        <div class="column">
          <div class="columns">
            <div class="column is-6">
              <p class="title">
                <a
                    class="has-text-underlined"
                    @click="showLearner"
                >{{ learner.first_name }} {{ learner.last_name }}</a
                >
              </p>
              <p>Application created: {{ application.age_human_readable }}</p>
            </div>
            <div class="column is-6">
              <div class="columns is-multiline  is-vcentered">
                <div class="column is-12">
                  <b-field group-multiline grouped>
                    <b-field>
                      <b-button
                          :disabled="!can_edit_application"
                          :outlined="application.victory_status_id !== 2"
                          class="button is-success"
                          @click="toggleVictoryStatus(2)"
                      >Won
                      </b-button>
                    </b-field>
                    <b-field>
                      <b-button
                          :disabled="!can_edit_application"
                          :outlined="application.victory_status_id !== 1"
                          class="button is-link"
                          @click="toggleVictoryStatus(1)"
                      >Open
                      </b-button>
                    </b-field>
                    <b-field>
                      <b-button
                          :disabled="!can_edit_application"
                          :outlined="application.victory_status_id !== 3"
                          class="button is-danger"
                          @click="toggleVictoryStatus(3)"
                      >Lost
                      </b-button>
                    </b-field>
                  </b-field>
                </div>
                <div class="column is-12 px-0">
                  <b-field expanded label="Priority">
                    <b-dropdown v-model="application_priority"
                                :disabled="!can_edit_application"
                                :loading="changing_priority" aria-role="list" expanded>
                      <template #trigger="{ active }">
                        <b-button
                            :icon-left="$tc('icons.circle')"
                            pack="'filled'"
                            :class="`priority-option-${application_priority}`"
                            :icon-right="active ? 'menu-up' : 'menu-down'"
                            :label="`${application_details.priority}`"
                            type="is-primary"/>
                      </template>


                      <b-dropdown-item v-for="number in 5" :key="number" :value="number" aria-role="listitem">
                        <div class="is-flex  is-align-items-center"
                        >{{ number }}
                          <span :class="`priority-option-${number}`" class="is-flex"><b-icon :icon="$tc('icons.circle')"
                                                                             pack="'filled'"
                                                                             size="is-small"></b-icon></span>
                        </div>
                      </b-dropdown-item>

                    </b-dropdown>

                  </b-field>
                </div>
                <div class="column is-12 px-0">
                  <b-field label="Status">
                    <b-select v-model="application_status"
                              :disabled="!can_edit_application"
                              :loading="applicationStatus"
                              expanded
                    >
                      <option v-for="status in statuses" :key="status.id" :value="status.id">{{ status.name }}</option>

                    </b-select>
                  </b-field>
                </div>
                <div class="column px-0 is-12">
                  <b-field label="Assign to">
                    <b-select v-model="owner"
                              :disabled="!can_edit_application"
                              :icon="$tc('icons.users')"
                              expanded
                              placeholder="Select the application owner"
                    >
                      <option
                          v-for="user in admissions_team"
                          :key="user.id"
                          :value="user"
                      >
                        {{ user.first_name }} {{ user.last_name }} |
                        {{ user.email }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column px-0 is-12">
                  <b-field label="Application Creator">
                    <b-select v-model="creator"
                              :disabled="true"
                              :icon="$tc('icons.users')"
                              expanded
                              placeholder="Select the application creator"
                    >
                      <option
                          v-for="user in admissions_team"
                          :key="user.id"
                          :value="user"
                      >

                        {{ user.first_name }} {{ user.last_name }} |
                        {{ user.email }}

                      </option>
                    </b-select>
                  </b-field>
                </div>
                <b-loading
                    :active.sync="setting_owner"
                    :can-cancel="false"
                    :is-full-page="false"
                ></b-loading>
              </div>
            </div>
          </div>
          <b-progress
              v-if="current_stage"
              :value="stageValue"
              show-value
              size="is-medium"
              type="is-success"
          >
            <p class="has-text-black">{{ current_stage.name }}</p>
          </b-progress>
        </div>
      </div>
    </section>
    <section class="px-2">
      <div class="columns">
        <div class="column is-one-third">
          <div class="columns is-multiline">
            <div class="column is-12 box">
              <card-component
                  :header-icon="$tc('icons.applications')"
                  title="Application Details"
                  un-card
              >
                <form @submit.prevent="submitApplicationDetails">
                  <b-field v-if="campuses.length>1" label="Campus">
                    <b-select v-model="application_details.campus_id" expanded>
                      <option v-for="campus in campuses" :key="campus.id" :value="campus.id">{{ campus.name }}</option>
                    </b-select>
                  </b-field>
                  <b-field v-if="application_details.programme_id===null" expanded label="Phase">
                    <b-select v-model="application_details.phase_id" :disabled="!application_details.campus_id" expanded
                              placeholder="Phase">
                      <option
                          v-for="phase in phases"
                          :key="phase.id"
                          :value="phase.id"
                      >{{ phase.name }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field v-if="application_details.programme_id===null" expanded label="Stage">
                    <b-select v-model="application_details.stage_id" :disabled="!application_details.phase_id" expanded
                              placeholder="Stage">
                      <option
                          v-for="stage in campus_stages"
                          :key="stage.id"
                          :value="stage.id"
                      >{{ stage.name }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field v-if="application_details.programme_id!==null" expanded label="Programme">
                    <b-select v-model="application_details.programme_id" :disabled="!application_details.campus_id" expanded
                              placeholder="Programme">
                      <option
                          v-for="stage in campus_stages"
                          :key="stage.id"
                          :value="stage.id"
                      >{{ stage.name }}
                      </option>
                    </b-select>
                  </b-field>


                  <b-field expanded label="Month">
                    <b-field expanded>
                      <b-select v-model="application_details.month" expanded>
                        <option :value="1">January</option>
                        <option :value="2">February</option>
                        <option :value="3">March</option>
                        <option :value="4">April</option>
                        <option :value="5">May</option>
                        <option :value="6">June</option>
                        <option :value="7">July</option>
                        <option :value="8">August</option>
                        <option :value="9">September</option>
                        <option :value="10">October</option>
                        <option :value="11">November</option>
                        <option :value="12">December</option>
                      </b-select>
                    </b-field>
                  </b-field>
                  <b-field label="Year">
                    <b-field>
                      <b-input
                          v-model="application_details.year"
                          type="number"
                      ></b-input>
                    </b-field>
                  </b-field>
                  <b-field label="Payment Responsibility">
                    <b-input v-model="application_details.payment_responsibility"></b-input>
                  </b-field>
                  <b-field label="Message">
                    <b-input
                        v-model="application_details.message"
                        placeholder="Explain how we can help you"
                        type="textarea"
                    />
                  </b-field>
                  <b-field expanded label="Fee Paid At">
                    <b-datepicker
                        v-model="fee_paid_at"
                        :years-range="[-10,10]"
                        expanded placeholder="Fee paid at"
                        position="is-top-right"></b-datepicker>
                  </b-field>
                  <b-field>
                    <b-field>
                      <div class="columns is-mobile">
                        <div class="column is-6 has-text-left">
                          <b-button
                              :loading="savingApplicationDetails"
                              native-type="submit"
                              type="is-primary"
                          >Submit
                          </b-button
                          >
                        </div>
                        <div class="column is-6 has-text-right">
                          <b-button
                              v-if="delete_applications"
                              type="is-danger"
                              @click="start_delete_application(application.id)"
                          >Delete
                          </b-button
                          >
                        </div>
                      </div>
                    </b-field>
                  </b-field>
                </form>
              </card-component>
            </div>
            <div v-for="guardian in guardians" :key="guardian.id" class="column is-12 box">
              <card-component
                  :header-icon="$tc('icons.guardians')"
                  title="Guardian Details"
              >
                <div>
                  <b-field expanded label="Title">
                    <b-input :value="guardian.title" disabled>
                    </b-input>
                  </b-field>
                  <b-field expanded label="First Name">
                    <b-input :value="guardian.first_name" disabled>
                    </b-input>
                  </b-field>
                  <b-field expanded label="Last Name">
                    <b-input :value="guardian.last_name" disabled>
                    </b-input>
                  </b-field>
                  <b-field expanded label="ID number">
                    <b-input :value="guardian.profile?guardian.profile.id_number:'No profile'" disabled>
                    </b-input>
                  </b-field>
                  <b-field expanded label="Primary Contact Number">
                    <b-input :value="guardian.primary_contact_number" disabled>
                    </b-input>
                  </b-field>
                  <b-field expanded label="Cellphone Number">
                    <b-input :value="guardian.cellphone_number" disabled>
                    </b-input>
                  </b-field>
                  <b-field expanded label="Email Address">
                    <b-input :value="guardian.email" disabled>
                    </b-input>
                  </b-field>


                </div>
              </card-component>
            </div>
          </div>
        </div>
        <div ref="tabs" class="column ml-1 is-two-third">
          <div class="columns box">
            <div class="column">
              <b-tabs expanded multiline  v-model="activeTab" :destroy-on-hide="true">
                <b-tab-item :icon="$tc('icons.media')" label="Media">
                  <MediaPanel :canCreate="!!$store.getters['entities/user-permissions/find']('create media')" :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
                              :model="'applications'"
                              :tags="tags"
                              :model_id="parseInt(id)"
                  ></MediaPanel>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.note')" label="Notes">
                  <NoteForm
                      :model_id="parseInt(id)"
                      :model_type="'applications'"
                      :permissions="{
                      can_create:!!$store.getters['entities/user-permissions/find']('create notes')
                            }
                  "
                  ></NoteForm>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.activities')" label="Activity">
                  <activityForm

                      :action="editingActivity ? 'edit' : 'create'"
                      :activity="editingActivity ? activityToEdit : null"
                      :model_id="parseInt(id)"
                      :model_type="'applications'"
                      :permissions="{
                      can_create: can_edit_application,
                      can_edit: can_edit_application,
                    }"
                      :team="admissions_team"
                      @cancelActivity="editingActivity = false;"
                  ></activityForm>
                </b-tab-item>
                <!--                <b-tab-item v-if="can_create_emails" label="Email" icon="email">-->
                <!--                  <emailForm-->
                <!--                      :username_missing="$store.state.userEmail == null"-->
                <!--                      :permissions="{ can_create: can_edit_application }"-->
                <!--                      :reply_email="reply_email"-->
                <!--                      :default_recipient="learner"-->
                <!--                  ></emailForm>-->
                <!--                </b-tab-item>-->
              </b-tabs>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-tabs expanded multiline
                  v-model="list"
                  class="block"

                  position="is-centered"
              >
                <b-tab-item label="All">
                  <div
                      v-for="(item, index) in allListItems"
                      :key="index + 'list'"
                  >
                    <activityListItem
                        v-if="item.type === 'tasks'"
                        :activity="item"
                        :model_id="parseInt(id)"
                        :model_type="'applications'"
                        :permissions="{
                        can_delete: can_delete_activity(item),
                        can_edit: can_edit_activity(item),
                        can_complete: can_edit_application,
                      }"
                        @editActivity="editActivity($event)"
                    ></activityListItem>
                    <noteListItem
                        v-if="item.type === 'notes'"
                        :model_id="parseInt(id)"
                        :model_type="'applications'"
                        :note="item"
                        :permissions="{
                                            can_delete: delete_notes ,
                                            can_edit: edit_notes,
                                          }"
                    ></noteListItem>
                    <!--                    <emailListItem-->
                    <!--                        @reply="reply"-->
                    <!--                        :email_direction="-->
                    <!--                        item.from_user_id == learner.id ? 'Received' : 'Sent'-->
                    <!--                      "-->
                    <!--                        v-if="item.type == 'emails' && can_view_emails"-->
                    <!--                        :email="item"-->
                    <!--                    ></emailListItem>-->
                  </div>
                </b-tab-item>
                <b-tab-item label="Activities">
                  <div
                      v-for="activity in paginated_activities"
                      :key="activity.id + 'activity'"
                  >
                    <activityListItem
                        :activity="activity"
                        :model_id="parseInt(id)"
                        :model_type="'applications'"
                        :permissions="{
                        can_delete: can_delete_activity(activity),
                        can_edit: can_edit_activity(activity),
                        can_complete: can_edit_application,
                      }"
                        @editActivity="editActivity($event)"
                    ></activityListItem>
                  </div>
                  <b-pagination
                      :current.sync="pages.activities"
                      :per-page="15"
                      :range-after="2"
                      :range-before="2"
                      :total="activities.length"
                      aria-current-label="Current page"
                      aria-next-label="Next page"
                      aria-page-label="Page"
                      aria-previous-label="Previous page"
                      class="margin-top"
                  ></b-pagination>
                </b-tab-item>
                <b-tab-item label="Notes">
                  <div v-for="note in paginated_notes" :key="note.id + 'note'">
                    <noteListItem
                        :model_id="parseInt(id)"
                        :model_type="'applications'"
                        :note="note"
                        :permissions="{
                                        can_delete: delete_notes,
                                        can_edit: edit_notes,
                                      }"
                    ></noteListItem>
                  </div>
                  <b-pagination
                      :current.sync="pages.notes"
                      :per-page="15"
                      :range-after="2"
                      :range-before="2"
                      :total="notes.length"
                      aria-current-label="Current page"
                      aria-next-label="Next page"
                      aria-page-label="Page"
                      aria-previous-label="Previous page"
                      class="margin-top"
                  ></b-pagination>
                </b-tab-item>
                <b-tab-item label="Log">
                  <div v-for="log in sorted_log" :key="log.id + 'log'">
                    <logListItem :log="log"></logListItem>
                  </div>
                </b-tab-item>
                <!--                <b-tab-item v-if="can_view_emails" label="Emails">-->
                <!--                  <div-->
                <!--                      :key="email.id + 'email'"-->
                <!--                      v-for="email in paginated_emails"-->
                <!--                  >-->
                <!--                    <emailListItem-->
                <!--                        @reply="reply"-->
                <!--                        :email_direction="-->
                <!--                        email.from_user_id == learner.id ? 'Received' : 'Sent'-->
                <!--                      "-->
                <!--                        :email="email"-->
                <!--                    ></emailListItem>-->
                <!--                  </div>-->
                <!--                  <b-pagination-->
                <!--                      class="margin-top"-->
                <!--                      :total="emails.length"-->
                <!--                      :current.sync="pages.emails"-->
                <!--                      :range-before="2"-->
                <!--                      :range-after="2"-->
                <!--                      :per-page="15"-->
                <!--                      aria-next-label="Next page"-->
                <!--                      aria-previous-label="Previous page"-->
                <!--                      aria-page-label="Page"-->
                <!--                      aria-current-label="Current page"-->
                <!--                  ></b-pagination>-->
                <!--                </b-tab-item>-->
              </b-tabs>
              <hr/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--    <b-modal :active.sync="acceptance_modal">-->
    <!--      <div class="box" style="min-height: 300px">-->
    <!--        <div class="columns is-multiline is-centered">-->
    <!--          <b-field class="column is-narrow" grouped>-->
    <!--            <b-field>-->
    <!--              <b-dropdown v-model="acceptance_letter_type" aria-role="list">-->
    <!--                <button-->
    <!--                    class="button is-light"-->
    <!--                    slot="trigger"-->
    <!--                    slot-scope="{ active }"-->
    <!--                >-->
    <!--                  <span class="is-capitalized">{{-->
    <!--                      acceptance_letter_type == null-->
    <!--                          ? "Letter type"-->
    <!--                          : acceptance_letter_type-->
    <!--                    }}</span>-->
    <!--                  <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>-->
    <!--                </button>-->

    <!--                <b-dropdown-item aria-role="listitem" value="standard"-->
    <!--                >Standard-->
    <!--                </b-dropdown-item-->
    <!--                >-->

    <!--                <b-dropdown-item aria-role="listitem" value="adaptable"-->
    <!--                >Adaptable-->
    <!--                </b-dropdown-item-->
    <!--                >-->

    <!--                <b-dropdown-item aria-role="listitem" value="conditional"-->
    <!--                >Conditional-->
    <!--                </b-dropdown-item-->
    <!--                >-->

    <!--                <b-dropdown-item aria-role="listitem" value="refused"-->
    <!--                >Refused-->
    <!--                </b-dropdown-item-->
    <!--                >-->
    <!--              </b-dropdown>-->
    <!--            </b-field>-->
    <!--            <b-field v-if="acceptance_letter_type != 'adaptable'">-->
    <!--              <b-button-->
    <!--                  @click="handle_acceptance_click(application_details.id)"-->
    <!--                  class="is-capitalized"-->
    <!--                  :disabled="acceptance_letter_type == null"-->
    <!--                  type="is-primary"-->
    <!--              >{{ acceptance_letter_action }}-->
    <!--              </b-button-->
    <!--              >-->
    <!--            </b-field>-->
    <!--          </b-field>-->
    <!--        </div>-->
    <!--        <b-field class="content">-->
    <!--          <ckeditor-->
    <!--              v-if="acceptance_letter_type == 'adaptable'"-->
    <!--              :editor="editor"-->
    <!--              v-model="acceptance_letter"-->
    <!--              :config="editorConfig"-->
    <!--          ></ckeditor>-->
    <!--        </b-field>-->
    <!--        <b-field v-if="acceptance_letter_type == 'adaptable'">-->
    <!--          <b-button-->
    <!--              @click="handle_acceptance_click(application_details.id)"-->
    <!--              class="is-capitalized"-->
    <!--              type="is-primary"-->
    <!--          >{{ acceptance_letter_action }}-->
    <!--          </b-button-->
    <!--          >-->
    <!--        </b-field>-->
    <!--      </div>-->
    <!--    </b-modal>-->
  </div>
</template>

<script>
import CardComponent from "@/components/layout/CardComponent";
import Application from "@/models/Application";
import User from "@/models/User";
import ApplicationStage from "@/models/ApplicationStage";
import ApplicationNote from "@/models/Note";
// import Email from "@/models/Email";
// import axios from "axios";
// import {API_URL, ACCESS_TOKEN} from "@/config";
// import EmailForm from "@/components/EmailForm";
import ActivityForm from "@/components/activities/ActivityForm";
import ActivityListItem from "@/components/activities/ActivityListItem";
// import EmailListItem from "@/components/EmailListItem";
import NoteListItem from "@/components/notes/NoteListItem";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import LogListItem from "@/components/log/LogListItem";
import Lookup from "@/models/Lookup";
import ApplicationStatus from "@/models/ApplicationStatus";
import Task from "@/models/Task";
import Campus from "@/models/Campus";
import UserProfile from "@/components/users/UserProfile";
import MediaPanel from "@/components/media/MediaPanel";
import NoteForm from "@/components/notes/NoteForm";
import Stage from "@/models/Stage";
import Phase from "@/models/Phase";
import {format} from "date-fns";
import Profile from "@/models/Profile";
import Programme from "@/models/Programme";

export default {
  name: "EditApplication",
  props: ["id"],
  components: {
    MediaPanel,
    CardComponent,
    ActivityForm,
    ActivityListItem,
    LogListItem,
    NoteForm,
    // EmailListItem,
    NoteListItem,
    // EmailForm,
  },
  data() {
    return {
      list: 0,
      reply_email: {},
      selectedModules: [],
      filteredModules: [],
      // applications_stage_ids: [10, 11, 12, 14, 15],
      // applications_stage_ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 13],
      savingApplicationDetails: false,
      savingLearnerDetails: false,
      setting_owner: false,
      tags: [
        "Academic",
        "Medical",
        "Personal",
        "Identification",
        "Photographic",
        "Financial"
      ],
      courseHold: "",
      pages: {notes: 1, activities: 1, emails: 1},
      selectedModuleIds: [],
      activityToEdit: null,
      editingActivity: false,
      savingNote: false,
      moving: false,
      changing_priority: false,
      applicationStatus: false,
      editorData: "",
      noteBeingEdited: Object,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "Note body"},
      },
      date: null,
      activeTab: 0,
      application_details_lock: true,
      learner_details_lock: true,
      filteredCourses: [],
      loadingCourses: false,
      loaded: false,
      learner_details: Object,
      application_details: Object,
      admissions_team: [],
      acceptance_letter: null,
      acceptance_modal: false,
      acceptance_letter_type: null,
      acceptance_letter_action: null,
    };
  },
  computed: {
    programmes() {
      return Programme.query().orderBy('name').get()
    },
    guardians() {
      return User.query().whereId(this.learner.id).with('guardians').with('guardians.profile').first().guardians
    },
    fee_paid_at: {
      get() {
        if (this.application_details.reg_fee_paid_at === null) {
          return null
        }
        return new Date(this.application_details.reg_fee_paid_at)
      }, set(newValue) {
        this.application_details.reg_fee_paid_at = format(newValue, 'yyyy-MM-dd')
      },
    },
    campuses() {
      return Campus.query().orderBy('ordinality').get()
    },
    statuses() {
      return ApplicationStatus.query().get()
    },
    sources() {
      return Lookup.query().where("lookup_type_id", 7).get();
    },
    sorted_log() {
      let sorted = this.application.activities;
      sorted.sort(function compare(a, b) {
        const dateA = new Date(a.attributes.created_at);
        const dateB = new Date(b.attributes.created_at);
        return dateB - dateA;
      });
      return sorted;
    },
    application_stage: {
      get() {
        return this.application_details.stage_id
      },
      set(newValue) {
        this.moving = true;
        this.application_details.stage_id = newValue;
        Application.update({
          where: this.application.id,
          data: {stage_id: newValue},
        });

        this.submitApplicationDetails().then(() => {
          this.moving = false;
        });
      }
    }, application_priority: {
      get() {
        return this.application_details.priority
      },
      set(newValue) {
        this.changing_priority = true;

        this.application_details.priority = newValue;
        Application.update({
          where: this.application.id,
          data: {priority: newValue},
        });

        this.submitApplicationDetails().then(() => {
          this.changing_priority = false;
        });
      }
    },
    application_status: {
      get() {
        return this.application_details.status_id;
      },
      set(newValue) {
        this.toggleStatus(newValue);
      },
    },
    allListItems() {
      let composite = [...this.activities, ...this.notes];
      composite = composite.sort(function compare(a, b) {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      return composite.slice(0, 15);
    },
    paginated_activities() {
      return Task.query()
          .with("creator")
          .with("assigned_to_user")
          .with("completed_user")
          .orderBy("created_at", "desc")
          .offset((this.pages.activities - 1) * 15)
          .limit(15)
          .get();
    },
    paginated_notes() {
      return ApplicationNote.query()
          .with("user")
          .orderBy("created_at", "desc")
          .offset((this.pages.notes - 1) * 15)
          .limit(15)
          .get();
    },
    notes() {
      return ApplicationNote.query()
          .with("user")
          .orderBy("created_at", "desc")
          .get();
    },
    activities() {
      return Task.query()
          .with("creator")
          .with("assigned_to_user")
          .with("completed_user")
          .orderBy("created_at", "desc")
          .get();
    },
    // emails() {
    //   return Email.query().orderBy("created_at", "desc").with("media").get();
    // },
    // paginated_emails() {
    //   return Email.query()
    //       .orderBy("created_at", "desc")
    //       .with("media")
    //       .offset((this.pages.emails - 1) * 15)
    //       .limit(15)
    //       .get();
    // },
    delivery_type: {
      get() {
        if (this.application_details.delivery_type_id != null) {
          return this.application_details.delivery_type_id.toString();
        }
        return "";
      },
      set(newValue) {
        this.application_details.delivery_type_id = parseInt(newValue);
      },
    },
    totalCredits() {
      let total = 0;
      this.selectedModules.forEach((module) => {
        total += module.credits;
      });
      return total;
    },
    enrolment_date: {
      set(newValue) {
        this.date = new Date(newValue);
      },
      get() {
        return new Date(this.date);
      },
    },
    // courseName() {
    //   if (this.application.course) {
    //     return this.application.course.name;
    //   }
    //   return "No " + this.$tc("Course", 1);
    // },
    administrate_applications() {
      return this.$store.getters["entities/user-permissions/find"](
          "edit applications"
      );
    },
    delete_applications() {
      return this.$store.getters["entities/user-permissions/find"](
          "delete applications"
      );
    },
    administrate_notes() {
      return this.$store.getters["entities/user-permissions/find"](
          "edit notes"
      );
    },
    edit_notes() {
      return this.$store.getters["entities/user-permissions/find"]("edit notes");
    },
    delete_notes() {
      return this.$store.getters["entities/user-permissions/find"]("delete notes");
    },
    creator: {
      get() {
        return User.find(parseInt(this.application.creator_id));
      },
      set(creator) {
        this.setting_owner = true;

        Application.update({
          where: this.application.id,
          data: {creator_id: creator.id},
        });
        this.application_details.creator_id = creator.id;
        this.submitApplicationDetails().then(() => {
          this.setting_owner = false;
          this.$buefy.snackbar.open('Creator Updated!')

        });
      },
    },
    owner: {
      get() {
        return User.find(parseInt(this.application.owner_id));
      },
      set(owner) {
        this.setting_owner = true;
        Application.update({
          where: this.application.id,
          data: {owner_id: owner.id},
        });
        this.application_details.owner_id = owner.id;
        this.submitApplicationDetails().then(() => {
          this.setting_owner = false;
          this.$buefy.snackbar.open('Owner Updated!')

        });
      },
    },
    learner_details_lock_icon() {
      if (this.learner_details_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    application_details_lock_icon() {
      if (this.application_details_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    can_edit_learner() {
      return this.$store.getters["entities/user-permissions/find"]("edit learners");
    },
    can_edit_application() {
      return this.$store.getters["entities/user-permissions/find"](
          "edit applications"
      );
    }, can_administrate_applications() {
      return this.$store.getters["entities/user-permissions/find"](
          "edit applications"
      );
    },
    // can_create_emails() {
    //   return this.$store.getters["entities/user-permissions/find"]("create emails");
    // },
    // can_view_emails() {
    //   return this.$store.getters["entities/user-permissions/find"]("view emails");
    // },
    application() {
      return Application.query()
          .with("user").with('owner').with('creator')
          .with("stage").with('application_stage')
          .where("id", this.id)
          .get()[0];
    },

    learner() {
      return this.application.user;
    },
    users() {
      return User.all();
    },
    // course() {
    //   return this.application.course;
    // },
    current_stage() {
      return this.application.application_stage;
    },
    campus_stages() {
      return Stage.query().where('campus_id', this.application_details.campus_id).where('phase_id', this.application_details.phase_id).get()

    },
    phases() {
      return Phase.query().where('campus_id', this.application_details.campus_id).get()

    },
    stages() {
      return ApplicationStage.query().orderBy("order", "asc").get();
    },

    stageValue() {
      return (
          ((this.stages.findIndex((x) => x.id === this.current_stage.id) + 1) /
              this.stages.length) *
          100
      );
    },
  },
  methods: {

    toggleVictoryStatus(status) {
      if (this.application.victory_status_id == status) {
        return;
      }
      this.applicationStatus = true;

      if (status == 1) {
        Application.SetStatusNone(this.application.id).then(() => {
          this.applicationStatus = false;
          Application.update({
            where: this.application.id,
            data: {victory_status_id: status},
          });
        });
      }
      if (status == 2) {
        Application.SetStatusWon(this.application.id).then(() => {
          this.applicationStatus = false;
          Application.update({
            where: this.application.id,
            data: {victory_status_id: status},
          });
        });
      }
      if (status == 3) {
        Application.SetStatusLost(this.application.id).then(() => {
          this.applicationStatus = false;
          Application.update({
            where: this.application.id,
            data: {victory_status_id: status},
          });
        });
      }
    },

    showLearner() {
      if (this.$store.getters['entities/user-permissions/find']('edit learners')) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            type: 'Learner',
            tabs: [
              {title: 'User Details', role: 'details'},
              {title: 'Profile', role: 'profile'},
              {title: 'Enrolments', role: 'enrolments'},
              {title: 'Guardians', role: 'guardians'},
              {title: 'Services', role: 'services'},
              {title: 'Notes', role: 'notes'},
              ...(this.$store.getters['entities/user-permissions/find']('view media') ? [{
                title: 'Media',
                role: 'media'
              }] : []),              {title: 'Observations', role: 'observations'},
              {title: 'Activities', role: 'activities'},

            ], profileFields:
                [
                  'date_of_birth',
                  'id',
                  'gender',
                  'language',
                  'cemis_number', 'school',
                  'grade',
                  'dominant_hand',
                  'ethnicity',
                  'home_address', 'siblings', 'religion', 'medical_information', 'emergency_contact', 'consent', 'lunch', 'day_length'
                ],
            proppedUser: this.learner,
            canEdit: !!this.$store.getters["entities/user-permissions/find"](
                "edit learners"
            ),
            canDelete: !!this.$store.getters["entities/user-permissions/find"](
                "delete learners"
            ),
            canCreateEnrolment: !!this.$store.getters['entities/user-permissions/find']('create enrolments'),
            canDeleteEnrolment: !!this.$store.getters['entities/user-permissions/find']('delete enrolments')
            ,
            canEditEnrolment: !!this.$store.getters['entities/user-permissions/find']('edit enrolments')

            , canCreateSubject:
                !!this.$store.getters['entities/user-permissions/find']('create subjects')

            , canDeleteSubject:
                !!this.$store.getters['entities/user-permissions/find']('delete subjects')

            , canEditSubject:
                !!this.$store.getters['entities/user-permissions/find']('edit subjects')
            , canCreateExtramural:
                !!this.$store.getters['entities/user-permissions/find']('create extramurals')

            , canDeleteExtramural:
                !!this.$store.getters['entities/user-permissions/find']('delete extramurals')

            , canEditExtramural:
                !!this.$store.getters['entities/user-permissions/find']('edit extramurals')

            , canCreate:
                !!this.$store.getters['entities/user-permissions/find']('create learners')

            , canCreateGuardian:
                !!this.$store.getters['entities/user-permissions/find']('edit learners')

            , canDeleteGuardian:
                !!this.$store.getters['entities/user-permissions/find']('edit learners')

            , canEditGuardian:
                !!this.$store.getters['entities/user-permissions/find']('edit learners')

            ,
            canCreateWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')

            ,
            canDeleteWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')

            ,
            canEditWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')

            ,
            canCreateLearner: !!this.$store.getters['entities/user-permissions/find']('create learners')
          },
          component: UserProfile,
          fullScreen: true,
          trapFocus: true,
        })
      } else {
        this.$store.dispatch('toast/createToast')
      }
    },


    can_edit_activity(activity) {
      return !!(this.$store.getters["entities/user-permissions/find"]("edit tasks") ||
          parseInt(activity.creator_id) === this.$store.state.user.id);

    }
    ,
    can_delete_activity(activity) {
      return !!(this.$store.getters["entities/user-permissions/find"]("delete tasks") ||
          parseInt(activity.creator_id) === this.$store.state.user.id);
    }
    ,
    setPage(event, type) {
      this.pages[type] = event;
    }
    ,
    reply($evt) {
      this.activeTab = 4;
      this.reply_email = $evt;
      this.$refs["tabs"].scrollIntoView({
        behavior: "smooth",
      });
    }
    ,
    editActivity(event) {
      this.activeTab = 3;

      this.editingActivity = true;
      this.activityToEdit = Task.query()
          .with("creator")
          .whereId(event)
          .get()[0];

      this.$refs["tabs"].scrollIntoView({
        behavior: "smooth",
      });
    }
    ,
// handle_acceptance_click(id) {
//   let options = {
//     type: this.acceptance_letter_type,
//     ...(this.acceptance_letter_type == "adaptable"
//         ? {
//           body: this.acceptance_letter,
//         }
//         : {}),
//   };
//   if (this.acceptance_letter_action == "send") {
//     this.$store.dispatch("loader/show");
//
//     Application.emailAcceptance(id, options)
//         .then(() => {
//           this.$store.dispatch("loader/hide");
//           this.acceptance_modal = false;
//
//           this.$buefy.snackbar.open({
//             message: "Email Sent",
//             type: "is-link",
//           });
//         })
//         .catch((err) => {
//           this.$store.dispatch("toast/createToast", {
//             message: JSON.stringify(err.response.data),
//           });
//         });
//   } else if (this.acceptance_letter_action == "download") {
//     this.$store.dispatch("loader/show");
//     axios({
//       url: `${API_URL}/applications/${id}/download-acceptance-letter`,
//       method: "post",
//       responseType: "arraybuffer",
//       data: options,
//       headers: {
//         Accept: "*/*",
//         "Content-Type": "application/json",
//
//         Authorization: "Bearer " + ACCESS_TOKEN,
//         "X-Requested-With": "XMLHttpRequest",
//       },
//     })
//         .then((response) => {
//           const url = window.URL.createObjectURL(new Blob([response.data]));
//           const link = document.createElement("a");
//           link.href = url;
//           link.setAttribute(
//               "download",
//               `${this.learner_details.student_number}_acceptance_letter.pdf`
//           );
//           document.body.appendChild(link);
//           link.click();
//           this.$buefy.snackbar.open({
//             message: "Download opened",
//             type: "is-link",
//           });
//           this.acceptance_modal = false;
//           this.$store.dispatch("loader/hide");
//         })
//         .catch((err) => {
//           this.$store.dispatch("toast/createToast", {
//             message: JSON.stringify(err.response.data),
//           });
//           this.$store.dispatch("loader/hide");
//         });
//   }
// },
// email(id) {
//   this.$store.dispatch("loader/show");
//   Application.emailProof(id)
//       .then(() => {
//         this.$buefy.snackbar.open({
//           message: "Email Sent",
//           type: "is-link",
//         });
//         this.$store.dispatch("loader/hide");
//       })
//       .catch((err) => {
//         this.$store.dispatch("toast/createToast", {
//           message: JSON.stringify(err.response.data),
//         });
//
//         this.$store.dispatch("loader/hide");
//       });
// },
// download_proof(id) {
//   this.$store.dispatch("loader/show");
//   axios({
//     url: `${API_URL}/applications/${id}/download-proof-of-application`,
//     method: "post",
//     responseType: "arraybuffer",
//
//     headers: {
//       Accept: "*/*",
//       "Content-Type": "application/json",
//
//       Authorization: "Bearer " + ACCESS_TOKEN,
//       "X-Requested-With": "XMLHttpRequest",
//     },
//   })
//       .then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute(
//             "download",
//             `${this.learner_details.student_number}_proof_of_application.pdf`
//         );
//         this.$buefy.snackbar.open({
//           message: "Download opened",
//           type: "is-link",
//         });
//         document.body.appendChild(link);
//         link.click();
//         this.$store.dispatch("loader/hide");
//       })
//       .catch((err) => {
//         this.$store.dispatch("toast/createToast", {
//           message: JSON.stringify(err.response.data),
//         });
//         this.$store.dispatch("loader/hide");
//       });
// },
    checkLevel($evt, module) {
      if (this.application_details_lock) {
        return;
      }
      if (this.selectedModules.some((item) => item.id === module.id)) {
        this.removeModule(module.id);
      } else {
        this.confirmSelectModule(module);
      }
    }
    ,
    removeModule(module_id) {
      this.selectedModules = this.selectedModules.filter(
          (el) => el.id !== module_id
      );

      this.selectedModuleIds = this.selectedModuleIds.filter(
          (el) => el !== module_id
      );
    }
    ,
    confirmSelectModule(module) {
      this.loadingModuleAdd = true;
      this.selectedModuleIds.push(module.id);

      this.selectedModules.push(module);
      this.loadingModuleAdd = false;
    }
    ,

    return_caption(caption) {
      if (caption === "undefined") {
        return "None";
      } else {
        return caption;
      }
    }
    ,
    deleteApplication(applicationId) {
      this.savingApplicationDetails = true;
      this.$store.dispatch("loader/show");
      Application.Delete(applicationId)
          .then(() => {

            let applications_save = this.$cookies
.get("applications_save");
            if (applications_save != null) {
              this.$router.push(applications_save.route);
            } else {
              this.$router.push('/applications')
            }
          })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
            this.$store.dispatch("loader/hide");
          });
    },

    start_delete_application(applicationId) {
      this.$buefy.dialog.confirm({
        title: "Deleting Application",
        message:
            "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Application",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteApplication(applicationId),
      });

    }
    ,
    toggleLearnerDetailsLock() {
      if (this.can_edit_learner) {
        this.learner_details_lock = !this.learner_details_lock;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    }
    ,
    toggleApplicationDetailsLock() {
      if (
          this.can_edit_application &&
          this.application.status_id !== 3
      ) {
        this.application_details_lock = !this.application_details_lock;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    }
    ,

    selectModule(module) {
      if (module) {
        if (module.level > this.application_details.level) {
          this.$buefy.dialog.confirm({
            title: "Confirm Module Selection",
            message:
                "The level of this module is higher than the level the student is registering for, are you sure you want to pick it?",
            confirmText: "Confirm Selection",
            type: "is-danger",
            hasIcon: true,
            onConfirm: () => this.confirmSelectModule(module),
          });
        } else {
          this.confirmSelectModule(module);
        }
      }
    }
    ,
// getFilteredModule(text, limit = 45) {
//   this.loadingModules = true;
//
//   return Module.search(
//       text,
//       true,
//       1,
//       limit,
//       this.application_details.course_id,
//       null
//       // this.application_details.semester
//   ).then((result) => {
//     this.loadingModules = false;
//
//     this.filteredModules = result.response.data.data;
//   });
// },
// selectCourse(course) {
//   if (course) {
//     this.selectedModules = [];
//     this.selectedModuleIds = [];
//     this.loadingCourses = true;
//
//     this.application_details.course_id = course.id;
//     this.application_details.course = course;
//     Course.FetchById(course.id)
//         .then(() => {
//           this.getFilteredModule("", 999).then(() => {
//             this.loadingCourses = false;
//           });
//         })
//         .catch((err) => {
//           this.$store.dispatch("toast/createToast", {
//             message: JSON.stringify(err.response.data),
//           });
//         });
//   }
// },
// getFilteredCourse(text) {
//   this.loadingCourses = true;
//
//   Course.search(text, true, 1).then((result) => {
//     this.loadingCourses = false;
//     if (result.entities) {
//       this.filteredCourses = result.entities.courses;
//     } else {
//       this.filteredCourses = [];
//     }
//   });
// },
    submitLearnerDetails() {
      this.savingLearnerDetails = true;
      User.Update(this.learner_details, true).then(() => {
        this.savingLearnerDetails = false;
      });
    }
    ,
    submitApplicationDetails() {
      // let submit_date = new Date(this.date);
      // this.application_details.enrolment_date =
      //     submit_date.getFullYear() +
      //     "-" +
      //     (submit_date.getMonth() + 1) +
      //     "-" +
      //     submit_date.getDate();
      this.savingApplicationDetails = true;
      return Application.Update(this.application_details).then(() => {
        this.savingApplicationDetails = false;
        this.$buefy.snackbar.open('Application Updated!')
      }).catch(
          err => {
            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      );
    }
    ,
    toggleStatus(status) {
      this.applicationStatus = true;
      this.application_details.status_id = status;
      Application.update({
        where: this.application.id,
        data: {status_id: status},
      });
      this.submitApplicationDetails().then(() => {
        this.applicationStatus = false;
        this.$buefy.snackbar.open('Status Updated!')

      });
    }
    ,
  }
  ,
  mounted() {
    // Email.deleteAll();
    Task.deleteAll()
    ApplicationNote.deleteAll()
    Lookup.FetchAll({page: 1, limit: 9999});
    ApplicationStatus.FetchAll()
    Programme.FetchAll({page: 1, limit: 99})

    this.$store.dispatch("loader/show");
    Task.FetchAllByModel({
      page: 1,
      limit: 100
    }, {}, ['creator', 'assignedToUser', 'completedUser'], {type: 'applications', id: this.id});
    Application.FetchById(this.id, ['user', 'owner', 'creator', 'activities.user'])
        .then(() => {
          this.application_details = this.application;
          this.learner_details = this.learner;
          User.FetchById(this.learner.id, ["guardians"]).then(() => {
            this.guardians.map(guardian => Profile.FetchById(guardian.id))
          });
          Phase.FetchAll({page: 1, limit: 9999}, {
            campus_id: this.application.campus_id,
            year: this.application_details.year
          }, ['stages'])

          // Email.fetch(this.learner.id);

          // this.courseHold =
          //     this.application.course != null ? this.application.course.name : "";
          this.date = this.application.enrolment_date;

          // eslint-disable-next-line no-prototype-builtins
          // this.selectedModules = result.entities.hasOwnProperty("modules")
          //     ? result.entities.modules
          //     : [];
          // this.selectedModules.forEach((element) => {
          //   this.selectedModuleIds.push(element.id);
          // });
        })
        .then(() => {
          ApplicationStage.FetchAll();

          User.FetchAll({page: 1, limit: 999}, {
            user_permission: "process applications",
            campus_id: this.application_details.campus_id
          }).then((result) => {
            this.admissions_team = result.entities.users;
            if (!result.entities.users.some(user => {
              return user.id === this.application_details.creator_id
            })) {
              this.admissions_team.push(this.application_details.creator)
            }
            this.loaded = true;
            this.$store.dispatch("loader/hide");
          });
        })
        .then(() => {
          // if (this.application_details.course != null) {
          //   this.getFilteredModule("", 999);
          // }
          ApplicationNote.FetchAllByModel({page: 1, limit: 999}, {}, ['user'], {
            id: this.application.id,
            type: 'applications'
          }).then(
              () => {
                this.loaded = true;
                this.$store.dispatch("loader/hide");
              }
          );
          // let list = "<ul>";
          // this.selectedModules.forEach((module) => {
          //   list += `<li><strong>${module.name}</strong></li>`;
          // });
          // list += "</ul>";
          // this.acceptance_letter = `<p><strong>The modules you have to complete <u>in addition</u> to the compulsory ${this.application_details.course.long_name} are:</strong></p>${list}`;
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          if (
              err.response.status.toString()[0] === 4 ||
              err.response.status.toString()[0] === 5
          ) {
            setTimeout(() => {
              this.$router.go(-1);
            }, 2000);
          }
        });

  }
  ,
}
;
</script>

<style lang="scss">
// .status_container {
//   .button:focus {
//     background-color: white;
//   }
// }
</style>
