<template>
  <ApplicationEdit
      :id="id"
  />

</template>

<script>

import ApplicationEdit from "@/components/applications/ApplicationEdit";

export default {
  name: "ApplicationsEditView",
  components: {
    ApplicationEdit
  },
  props: ['id'],
  data() {
    return {}
  },

};
</script>

